<template>
  <Home v-if="page.id === 'home' || page.slug === 'home'" :page="page" />

  <div v-else class="w-full pt-20">
    <!-- hide when offer page -->
    <HeroDefault
      v-if="
        page.id !== '0b8c32c6-eaef-432f-b6c7-f7efe3bfaa17' &&
        page.id !== '2cb427b6-cfc5-4ebe-a63f-8d2b6897c2c7' &&
        page.id !== '6d06a063-f583-4b96-b56d-5a149db71d4a'
      "
      :page="page"
    />

    <ComponentsLoader
      v-if="page.content_elements"
      :components="page.content_elements"
    />
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
}
</script>
